import { array, boolean, number, object, string } from 'idonttrustlikethat';

const ucEcosystemResponseTechnology = object({
  slug: string,
  name: string,
  description: string,
  value: boolean,
}).nullable();

const ucEcosystemResponseTechnologies = array(ucEcosystemResponseTechnology).nullable();

const ucEcosystemResponseCategory = object({
  slug: string,
  uc_technologies: ucEcosystemResponseTechnologies,
}).nullable();

const ucEcosystemResponseCategories = array(ucEcosystemResponseCategory).nullable();

const ucEcosystemResponse = object({
  data: array(
    object({
      id: number.nullable(),
      attributes: object({
        additionalComment: string.nullable(),
        Use_case_id: string,
        data: object({
          uc_categories: ucEcosystemResponseCategories,
        }),
      }),
    }),
  ),
});

const ucEcosystemResponseEdit = object({
  data: object({
    id: number.nullable(),
    attributes: object({
      additionalComment: string.nullable(),
      Use_case_id: string,
      data: object({
        uc_categories: ucEcosystemResponseCategories,
      }),
    }),
  }),
});

type UCEcosystemResponse = typeof ucEcosystemResponse.T;
type UCEcosystemResponseCategories = typeof ucEcosystemResponseCategories.T;
type UCEcosystemResponseCategory = typeof ucEcosystemResponseCategory.T;
type UCEcosystemResponseTechnologies = typeof ucEcosystemResponseTechnologies.T;
type UCEcosystemResponseTechnology = typeof ucEcosystemResponseTechnology.T;
type UCEcosystemResponseEdit = typeof ucEcosystemResponseEdit.T;

export {
  ucEcosystemResponse,
  ucEcosystemResponseCategories,
  ucEcosystemResponseCategory,
  ucEcosystemResponseTechnologies,
  ucEcosystemResponseTechnology,
  ucEcosystemResponseEdit,
};
export type {
  UCEcosystemResponse,
  UCEcosystemResponseCategories,
  UCEcosystemResponseCategory,
  UCEcosystemResponseTechnologies,
  UCEcosystemResponseTechnology,
  UCEcosystemResponseEdit,
};
